<template>
  <header :class="[
    'landing-app-header__wrapper',
    { 'landing-app-header__wrapper--white': !isLandingHeroVisible },
  ]">
    <Logo
      :size="'sm'"
      :color="isLandingHeroVisible ? 'white' : 'black'"
      isFullTextLogo>
    </Logo>
    <ul class="landing-app-header__items-list">
      <template v-if="isUserAuthenticated">
        <li class="app-header__item">
          <router-link :to="{ name: isArtistUser ? 'ArtistProfile' : 'ClientBookings' }">
            <Avatar
              :imageSrc="croppedProfilePictureURL"
              :size="'xs'">
            </Avatar>
          </router-link>
        </li>
      </template>
      <template v-else>
        <template v-if="$mediaQueries.isDesktop">
          <li class="landing-app-header__items-item">
            <router-link :to="{ name: 'ArtistSignin' }">
              <Button
                isGhost
                :style="{ color: isLandingHeroVisible ? 'var(--color-white)' : 'var(--color-black)' }">
                {{ $t('common.connexion') }}
              </Button>
            </router-link>
          </li>
        </template>
        <li class="landing-app-header__items-item">
          <router-link :to="{ name: 'ArtistSignup' }">
            <Button
              :isWhite="isLandingHeroVisible"
              hasNoBorder>
              {{ $t('common.signup') }}
            </Button>
          </router-link>
        </li>
      </template>
    </ul>
  </header>
</template>

<script>

import {
  mapState,
  mapGetters,
}                                   from 'vuex';

import Logo                         from '../../../components/atoms/Logo/a-Logo.vue';
import Avatar                       from '../../../components/atoms/Avatar/a-Avatar.vue';
import Button                       from '../../../components/atoms/Button/a-Button.vue';
import { getCroppedPictureURL }     from '../../../utils/URLUtils.js';


export default {
  name: 'o-LandingAppHeader',
  components: {
    Logo,
    Avatar,
    Button,
  },
  computed: {
    ...mapState('Layout', ['isLandingHeroVisible']),
    ...mapState('User', ['user', 'isUserAuthenticated']),
    ...mapGetters({
      isArtistUser: 'User/IS_ARTIST_USER',
    }),
    croppedProfilePictureURL() {
      const { URLProfilePictures, ProfilePicturesCropsOptions } = this.user;

      return getCroppedPictureURL(URLProfilePictures, ProfilePicturesCropsOptions, {}, { c: 'fill', w: 100, h: 100, });
    },
  },
}

</script>

<style lang="scss">

.landing-app-header {
  &__wrapper {
    z-index: 1000;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    height: var(--space-header-height);
    padding: var(--space-base);
    transition: background-color .25s ease-out;

    @media screen and ($desktop) {
      padding: var(--space-base) var(--space-lg);
    }

    &--white {
      background-color: var(--color-white);
      box-shadow: 0 0 2px 2px hsla(0, 0%, 0%, .025);
    }
  }

  &__items-list {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &__items-item {
    display: flex;
    align-items: center;
  }
}

</style>
